// 定期报告
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'WDMC',
                label: '培训项目名称',
                minWidth: 200,
                showoverflowtooltip: true
            }, {
                prop: 'WDMC',
                label: '培训开始时间',
                minWidth: 100,
                showoverflowtooltip: true
            }, 
            {
                prop: 'WDMC',
                label: '培训截止时间',
                minWidth: 100,
                showoverflowtooltip: true
            }, {
                prop: 'SXRY',
                label: '受训人员数量(人)',
                minWidth: 220,
                // transList: {
                //     1: '保护状况报告',
                //     2: '反应性监测报告',
                //     3: '参考文档'
                // }
            }],
        },
    },
    snSearch: {
        selectData: [],
        searchBtn: {
            // buttonData: [{
            //     btnType: 'button',
            //     operateType: 'conditionSearchBtn',
            //     name: '高级查询',
            //     round: true,
            //     backColor: '#2a63d5',
            //     color: '#fff'
            // }]
        }
    }
};
export default selfData;